.newsContainer {
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
}

.mobileNewsContainer {
    overflow-x: scroll;
    display: flex;
    text-align: center;
    flex-direction: column;
    height: 50vh;
}

.newsContainer::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}
.date {
    background-color: none;
    /* margin-top: 16; */
    height: 40px;
    width: 100%;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    padding-left: 8px;
    /* color: #7e7b8b; */
    cursor: pointer
}

.PhoneInput {
    width: 104%;
    height: 40px;
    border: 1px solid #c4c4c4;
    padding: 8px;
    border-radius: 5px;
}
.navLink {
    color: #679ADA !important;
    font-weight: 500;
}

.navContainer {
    background-color: #1C53A5;
    padding: 10px;
    border-radius: 30px;
    font-size: 16px;
    padding-left: 24px;
    padding-right: 16px;
}

@media (max-width:1200px) {
    .navContainer {
        font-size: 14px;
    }
}

.linkContainer {
    text-transform: uppercase;
}


.logo {
    width: 140px;
    height: 30px;
    background-image: url('../../assets/images/logo247.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.betTypes{
   color: #1C53A5;
}
.types p {
    margin: 0;
}

@media (max-width:992px) {
    .sideBarContainer{
        /* background-color: #eeeeee; */
        text-align: center;
    }
    .types{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .types p{
        margin: 8px;
    }
     
}

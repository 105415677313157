@font-face {
    font-family: Poppins;
    src: url(./assets/fonts/Poppins-Regular.ttf);
}



* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins';
}


body {
    overflow-x: hidden;
}

.ButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -24px;
}